/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     OUESTADAM SissiF Web App Code                           !
  !     (C) COPYRIGHT OUESTADAM 2024                            !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : sissifMain.js                                      !
  !  Desc. : SissiF Front Server main page                      !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 19/02/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- External products
*/
import React, {useLayoutEffect, useState} from 'react';
import {
    Avatar, Box, ButtonBase,
    Container,
    createTheme,
    CssBaseline,
    Grid,
    Paper, styled,
    ThemeProvider,
    Typography
} from "@mui/material";
import {enUS, frFR} from "@mui/x-data-grid";
import {createRoot} from "react-dom/client";

/*
--- Ouestadam products
*/
import {sissifDefDevice, sissifDefModal_e, sissifDefOrientation, sissifDefPaintType} from "./sissifDef";
import {SissifLocaleSwitcherJSX} from "./sissifLocale";

/*
--- Ouestadam resources
*/
import backgroundSissif from "./images/backgroundSissifV2.jpg";
import painterCardSissif from "./images/painterCardSissif.jpg";
import {SissifCarouselJSX} from "./sissifCarousel";
import {SissifPainterJSX} from "./sissifPainter";


/*=============== Local JSX components =========================*/

/*
+-------------------------------------------------------------+
! Routine    : LocJSXImageButton                              !
! Description: JSX Define Image Button                        !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Element rendering                                    !
+-------------------------------------------------------------+
*/
const LocJSXImageButton = styled(ButtonBase)(({theme}) => ({
    position: 'relative',
    height: 80,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 80
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.50,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '3px solid currentColor',
            borderRadius: '15px'
        },
    },
}));

/*
+-------------------------------------------------------------+
! Routine    : LocJSXImageSrc                                 !
! Description: JSX Define Image Source                        !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Element rendering                                    !
+-------------------------------------------------------------+
*/
const LocJSXImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

/*
+-------------------------------------------------------------+
! Routine    : LocJSXImage                                    !
! Description: JSX Define Image                               !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Element rendering                                    !
+-------------------------------------------------------------+
*/
const LocJSXImage = styled('span')(({theme}) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

/*
+-------------------------------------------------------------+
! Routine    : LocJSXImageBackdrop                            !
! Description: JSX Define Image Back Drop                     !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Element rendering                                    !
+-------------------------------------------------------------+
*/
const LocJSXImageBackdrop = styled('span')(({theme}) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    borderRadius: "20px",
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
}));

/*
+-------------------------------------------------------------+
! Routine    : LocJSXImageMarked                              !
! Description: JSX Define Image Marked                        !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Element rendering                                    !
+-------------------------------------------------------------+
*/
const LocJSXImageMarked = styled('span')(({theme}) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));

/*
+-------------------------------------------------------------+
! Routine    : LocJSXPaintings                                !
! Description: JSX Main Page Paintings button                 !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXPaintings(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locModal = paramProps.modal;
    const locTextId = paramProps.textId;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;

    /*
    --- Create the Main page Contents
    */
    return (
        <LocJSXImageButton
            focusRipple
            key={locTextId}
            style={{
                width: "260px"
            }}
            onClick={() => {
                locCtx.currentModal = locModal;
                sissifMainRefreshPage_f(locCtx);
            }}>
            <LocJSXImageSrc/>
            <LocJSXImageBackdrop className="MuiImageBackdrop-root"/>
            <LocJSXImage>
                <Typography
                    component="span"
                    variant="h6"
                    color={locColors.foregroundMain}
                    sx={{
                        position: 'relative',
                        p: 4,
                        pt: 2,
                        pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                        fontFamily: "Agbalumo"
                    }}
                >
                    {locTrans.oeComTransGet_m(locTextId, "title")}
                    <LocJSXImageMarked className="MuiImageMarked-root"/>
                </Typography>
            </LocJSXImage>
        </LocJSXImageButton>
    )
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXPainter                                  !
! Description: JSX Main Page Painter                          !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXPainter(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;

    /*
    --- Create the Main page Contents
    */
    return (
        <LocJSXImageButton
            focusRipple
            key={"painter"}
            style={{
                width: "740px",
                height: "110px",
                mt: 4
            }}
            onClick={() => {
                locCtx.currentModal = sissifDefModal_e.painterModal;
                sissifMainRefreshPage_f(locCtx);
            }}>
            >
            <LocJSXImageSrc/>
            <LocJSXImage>
                <Typography
                    component="span"
                    variant={(locCtx.window_o.device === sissifDefDevice.mobile) ? "h4" : "h2"}
                    color={locColors.foregroundMain}
                    sx={{
                        display: 'flex',
                        position: 'relative',
                        p: 4,
                        pt: 2,
                        mt: 2,
                        pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                        fontFamily: "Agbalumo"
                    }}
                >
                    {locTrans.oeComTransGet_m("mainPainter", "title")}
                    <Avatar src={painterCardSissif}
                            sx={{
                                width: "100px", height: "100px", boxShadow: "1px 1px 4px black", ml: 3,
                                display: (locCtx.window_o.device === sissifDefDevice.mobile) ? "none" : ""
                            }}
                            alt={locTrans.oeComTransGet_m("mainPainter", "title")}/>
                </Typography>
            </LocJSXImage>
        </LocJSXImageButton>
    )
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXContentsDesktop                          !
! Description: JSX Main Page Contents for Desktop             !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXContentsDesktop(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Create the Main page Contents
    */
    return (
        <Paper style={{
            backgroundImage: `url(${backgroundSissif})`,
            backgroundPositionX: "center",
            backgroundSize: "cover",
            height: locCtx.window_o.height,
            width: locCtx.window_o.width
        }}>
            <Container sx={{
                mt: 0,
                mb: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "100%"
            }}>
                <Grid container direction="column" spacing={1} justifycontents="flex-start" sx={{mt: 1}}>
                    <Grid item xs={2}>
                        <Grid container direction="row" spacing={1} justifyContent="space-between"
                              sx={{mt: 1, mb: 4}}>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={8} justifyContent="center" sx={{textAlign: 'center'}}>
                                <LocJSXPainter ctx={locCtx}/>
                            </Grid>
                            <Grid item xs={2} justifyContent="flex-end">
                                <div style={{position: "absolute", top: "70px", right: "70px"}}>
                                    <SissifLocaleSwitcherJSX ctx={locCtx}/>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8} alignItems="center" sx={{mt: 5}}>
                        <Grid container direction="row" spacing={1} justifyContent="space-around"
                              sx={{mb: 1}}>
                            <Grid item xs={2}>
                                <Grid container direction="column" spacing={1} sx={{ml: 6}}>
                                    <Grid item>
                                        <LocJSXPaintings ctx={locCtx}
                                                         modal={sissifDefModal_e.largePaintingsModal}
                                                         textId={"mainLarge"}/>
                                    </Grid>
                                    <Grid item>
                                        <LocJSXPaintings ctx={locCtx}
                                                         modal={sissifDefModal_e.mediumPaintingsModal}
                                                         textId={"mainMedium"}/>
                                    </Grid>
                                    <Grid item>
                                        <LocJSXPaintings ctx={locCtx}
                                                         modal={sissifDefModal_e.smallPaintingsModal}
                                                         textId={"mainSmall"}/>
                                    </Grid>
                                    <Grid item>
                                        <LocJSXPaintings ctx={locCtx}
                                                         modal={sissifDefModal_e.setPaintingsModal}
                                                         textId={"mainSet"}/>
                                    </Grid>
                                    <Grid item>
                                        <LocJSXPaintings ctx={locCtx}
                                                         modal={sissifDefModal_e.pastelPaintingsModal}
                                                         textId={"mainPastel"}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={10}>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Grid container direction="row" justifyContent="center">
                            <Grid item xs={4}>
                            </Grid>
                            <Grid item xs={4} justifyContent="center" sx={{display: 'inline-flex'}}>
                                <Typography variant="body1"
                                            color={locColors.foregroundMain}
                                            sx={{position: "absolute", bottom: "30px", fontWeight: "bold"}}>
                                    {locTrans.oeComTransGet_m("main", "cookies")}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    )
}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXContentsMobile                           !
! Description: JSX Main Page Contents for Mobile              !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXContentsMobile(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locTrans = locCtx.trans_o;
    const locColors = locCtx.config_o.colors;
    /*
    --- Create the Main page Contents
    */
    return (
        <Paper style={{
            backgroundImage: `url(${backgroundSissif})`,
            backgroundPositionX: "center",
            backgroundSize: "cover",
            width: locCtx.window_o.width
        }}>
            <Container sx={{
                mt: 0,
                mb: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "100%"
            }}>
                <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    sx={{color: locColors.foregroundMain}}
                >
                    <Grid container direction="column" justifycontents="flex-start" alignItems="center"
                          sx={{mt: 0}}>
                        <Grid item sx={{display: "contents", mt: 0, mb: 0}}>
                            <LocJSXPainter ctx={locCtx}/>
                        </Grid>
                        <Grid item sx={{mt: 2, mb: 3}}>
                            <SissifLocaleSwitcherJSX ctx={locCtx}/>
                        </Grid>
                        <Grid item sx={{display: "contents", mb: 2}}>
                            <Box
                                width="260px"
                                display="flex"
                                alignItems="center"
                                marginBottom="10px"
                            >
                                <LocJSXPaintings ctx={locCtx} modal={sissifDefModal_e.largePaintingsModal}
                                                 textId={"mainLarge"}/>
                            </Box>
                        </Grid>
                        <Grid item sx={{display: "contents", mt: 6}}>
                            <Box
                                width="260px"
                                display="flex"
                                alignItems="center"
                                marginBottom="10px"
                            >
                                <LocJSXPaintings ctx={locCtx} modal={sissifDefModal_e.mediumPaintingsModal}
                                                 textId={"mainMedium"}/>
                            </Box>
                        </Grid>
                        <Grid item sx={{display: "contents", mt: 6}}>
                            <Box
                                width="260px"
                                display="flex"
                                alignItems="center"
                                marginBottom="10px"
                            >
                                <LocJSXPaintings ctx={locCtx} modal={sissifDefModal_e.smallPaintingsModal}
                                                 textId={"mainSmall"}/>
                            </Box>
                        </Grid>
                        <Grid item sx={{display: "contents", mt: 2}}>
                            <Box
                                width="260px"
                                display="flex"
                                alignItems="center"
                                marginBottom="10px"
                            >
                                <LocJSXPaintings ctx={locCtx} modal={sissifDefModal_e.setPaintingsModal}
                                                 textId={"mainSet"}/>
                            </Box>
                        </Grid>
                        <Grid item sx={{display: "contents", mt: 2}}>
                            <Box
                                width="260px"
                                display="flex"
                                alignItems="center"
                                marginBottom="10px"
                            >
                                <LocJSXPaintings ctx={locCtx} modal={sissifDefModal_e.pastelPaintingsModal}
                                                 textId={"mainPastel"}/>
                            </Box>
                        </Grid>
                        <Grid item sx={{pb: 2}}>
                            <Typography variant="body2"
                                        color={locColors.foregroundMain}
                                        sx={{fontWeight: "bold"}}>
                                {locTrans.oeComTransGet_m("main", "cookies")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Paper>
    )
}
// {locTrans.oaComTransGet_m("main", "cookies")}

/*
+-------------------------------------------------------------+
! Routine    : LocJSXStartModal                               !
! Description: JSX Start Modal if required                    !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXStartModal(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    /*
    --- Check if a Modal should be started
    */
    switch (locCtx.currentModal) {
        case sissifDefModal_e.noModal:
            return (<></>
            );
        case sissifDefModal_e.largePaintingsModal:
            return (<SissifCarouselJSX ctx={locCtx} type={sissifDefPaintType.large}/>);
        case sissifDefModal_e.mediumPaintingsModal:
            return (<SissifCarouselJSX ctx={locCtx} type={sissifDefPaintType.medium}/>);
        case sissifDefModal_e.smallPaintingsModal:
            return (<SissifCarouselJSX ctx={locCtx} type={sissifDefPaintType.small}/>);
        case sissifDefModal_e.setPaintingsModal:
            return (<SissifCarouselJSX ctx={locCtx} type={sissifDefPaintType.set}/>);
        case sissifDefModal_e.pastelPaintingsModal:
            return (<SissifCarouselJSX ctx={locCtx} type={sissifDefPaintType.pastel}/>);
        case sissifDefModal_e.painterModal:
            return (<SissifPainterJSX ctx={locCtx}/>);
        default:
            return (<></>
            );
    }
}

/*+-----------------------------------------------------------+
! Routine    : LocJSXMain                                     !
! Description: JSX Main page                                  !
!                                                             !
! IN:  - Properties including Context                         !
! OUT: - Page rendering                                       !
+-------------------------------------------------------------+
*/
function LocJSXMain(paramProps) {
    /*
    --- Initialisation
    */
    const locCtx = paramProps.ctx;
    const locColors = locCtx.config_o.colors;
    /*
    --- Get React state for refreshing the page
    */
    const [locMain_s, locMain_f] = React.useState(false);
    locCtx.refresh_o.main_f = locMain_f;
    locCtx.refresh_o.main_s = locMain_s;
    /*
    --- Check the orientation
    */
    locCtx.window_o.orientation = (window.matchMedia("(orientation: portrait)").matches) ?
        sissifDefOrientation.portrait : sissifDefOrientation.landscape;
    /*
    --- Define the device type
    */
    const locUserAgent = navigator.userAgent.toLowerCase();
    locCtx.window_o.agent = locUserAgent;
    const locIsMobile = /iphone|ipad|ipod|android|windows phone/g.test(locUserAgent);
    const locIsTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(locUserAgent);
    if (locIsTablet) locCtx.window_o.device = sissifDefDevice.tablet;
    else if (locIsMobile) {
        /*
        --- Mobile
        */
        locCtx.window_o.device = sissifDefDevice.mobile;
    } else {
        /*
        --- Desktop: Orientation is landscape
        */
        locCtx.window_o.device = sissifDefDevice.desktop;
        locCtx.window_o.orientation = sissifDefOrientation.landscape;
    }
    /*
    --- Set hook for Window resizing
    */
    [locCtx.window_o.width, locCtx.window_o.height] = SissifMainWindowResize_f();
    /*
    --- If the screen width is small then process it as Mobile
    */
    if (locCtx.window_o.width < 1024) {
        locCtx.window_o.device = sissifDefDevice.mobile;
    }
    /*
    --- Search the theme locale
    */
    let locLocale_o = frFR;
    if (locCtx.config_o.locale === "en-GB") locLocale_o = enUS;
    /*
    --- Prepare the main page display according the Device type
    */
    let locMainContainer;
    switch (locCtx.window_o.device) {
        case sissifDefDevice.mobile :
            locMainContainer = (
                <div>
                    <LocJSXContentsMobile ctx={locCtx}/>
                </div>
            );
            break;
        default :
            locMainContainer = (
                <div>
                    <LocJSXContentsDesktop ctx={locCtx}/>
                </div>
            );
            break;
    }
    const locBackGroundColor = locColors.backgroundMainBlack;
    /*
    --- Create the Theme
    */
    const locTheme = createTheme({
        palette: {
            background: {
                default: locBackGroundColor
            }
        }
    }, locLocale_o);
    /*
    --- Set a Background
    */
    const locStyle = {backgroundColor: locColors.backgroundMainBlack};
    /*
    --- Return the Main page
    */
    return (<ThemeProvider theme={locTheme}>
        <CssBaseline/>
        <Paper style={locStyle}>
            {locMainContainer}
            <LocJSXStartModal ctx={locCtx}/>
        </Paper>
    </ThemeProvider>);
}

/*=============== Exported functions ===========================*/

/*
+-------------------------------------------------------------+
! Routine    : sissifMain_f                                   !
! Description: Main Page function                             !
!                                                             !
! IN:  - Context                                              !
! OUT: - Main page rendering                                  !
+-------------------------------------------------------------+
*/
export function sissifMain_f(paramCtx) {
    /*
    --- Render the Main page
    */
    const locRoot = createRoot(document.getElementById('root'));
    locRoot.render(<React.StrictMode>
        <LocJSXMain ctx={paramCtx}/>
    </React.StrictMode>);
}

/*
+-------------------------------------------------------------+
! Routine    : sissifMainRefreshPage_f                        !
! Description: Request the refresh of the Login Modal         !
!                                                             !
! IN:  - Context                                              !
! OUT: - Nothing                                              !
+-------------------------------------------------------------+
*/
export function sissifMainRefreshPage_f(paramCtx) {
    paramCtx.refresh_o.main_s = !paramCtx.refresh_o.main_s;
    paramCtx.refresh_o.main_f(paramCtx.refresh_o.main_s);
}

/*
+-------------------------------------------------------------+
! Routine    : SissifMainWindowResize_f                       !
! Description: Manage hook for Window resizing                !
!                                                             !
! IN:  - Context                                              !
! OUT: - Nothing                                              !
+-------------------------------------------------------------+
*/
export const SissifMainWindowResize_f = () => {
    const [locWindowSize, locSetWindowSize_f] = useState([0, 0])
    const locUpdateWindowSize_f = () => {
        locSetWindowSize_f([window.innerWidth, window.innerHeight])
    }
    useLayoutEffect(() => {
        window.addEventListener('resize', locUpdateWindowSize_f);
        locUpdateWindowSize_f();
        return () => window.removeEventListener('resize', locUpdateWindowSize_f);
    }, [])
    return [locWindowSize[0], locWindowSize[1]]
}
